import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";

export default function UpdateChildren(props) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    /**
     * Form value when select changed
    */
    const [formValue, setFormValue] = useState({
        selectedChild: '',
        i: 0,
    });

    /**
     * Use for displaying error message
    */
    const [message, setMessage] = useState("");

    /**
     * Use for displaying update form
    */
    const [updateForm, setUpdateForm] = useState(false);

    /**
     * Use for setting select value
    */
    const [childValue, setChildValue] = useState(0);

    /**
     * Creates a list of children
     */
    const [children, setChildren] = useState([]);

    /**
     * Update children list
     */
    const getChildren = () => {
        if (props.loginStatus) {
            Axios.get("/children").then((response) => {
                // Set up select array
                let array = [];

                // Iterate through results
                for (let i = 0; i <= response.data.result.length - 1; i++) {
                    array.push(<option data-tag={i} key={response.data.result[i].childrenID} value={response.data.result[i].childrenID}>{response.data.result[i].name}</option>);
                }

                // Set select array
                setChildren([array]);
            });
        }
    }

    /**
     * On page load
    */
    useEffect(() => {
        // Get Credits
        props.getCredits();

        // Update children
        getChildren();
    }, []);

    /**
     * On credits change
    */
    useEffect(() => {
        // Get Credits
        props.getCredits();

        // Update children
        getChildren();
    }, [props.credits]);

    /**
     * Handles parent update child form change events
     */
    const handleChange = (event) => {
        // Name and value of select
        const { name, value } = event.target;

        // Data tag i increment
        let i = parseInt(event.target[event.target.selectedIndex].attributes["data-tag"].value);

        // Set child value
        setChildValue(value);

        // Reset message
        setMessage('');
        setUpdateForm(false);

        setFormValue((prevalue) => {
            return {
                [name]: value,
                ["i"]: i
            }
        });
    };

    /**
     * When update button clicked
    */
    const submitUpdate = (e) => {
        e.preventDefault();

        Axios.post("/getchild", {
            childid: formValue.selectedChild,
        }).then((response) => {
            setValue("name", response.data.response[0].name);
            setValue("month", response.data.response[0].birthday.split('-')[1]);
            setValue("day", response.data.response[0].birthday.split('-')[2].substr(0, 2));
            setValue("year", response.data.response[0].birthday.split('-')[0]);
            setValue("status", response.data.response[0].status);
            setValue("reason", response.data.response[0].reason);

            // Show form
            setUpdateForm(true);
        });
    }

    /**
     * When remove button clicked
    */
    const submitRemove = (e) => {
        e.preventDefault();

        Axios.post("/deletechild", {
            childid: formValue.selectedChild,
        }).then((response) => {
            let childrenCopy = [].concat(children[0]);
            childrenCopy.splice(parseInt(formValue.i), 1);
            setChildren([childrenCopy]);
            // Set child value
            setChildValue(0);
            props.getCredits();
            setMessage('');
            setFormValue((prev) => ({
                ...prev,
                selectedChild: 0,
            }));
        });
    }

    /**
     * When save button is pressed
    */
    const saveUpdate = (e) => {
        Axios.post("/updatechild", {
            childid: formValue.selectedChild,
            data: e,
        }).then((response) => {
            // Clear form
            setUpdateForm(false);

            // Make copy of array
            let childrenCopy = [].concat(children[0]);

            // Replace index with new option
            childrenCopy[formValue.i] = [<option data-tag={parseInt(formValue.i)} key={formValue.selectedChild} value={formValue.selectedChild}>{e.name}</option>];

            // Set options
            setChildren([childrenCopy]);

            // Add credit
            props.setCredits(props.credits + 1);
        });
    };

    /**
     * Array of years to be used for childens birthdays
    */
    const years = [];

    // Creates years for child birthdays
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        years.push(<option value={i} key={i}>{i}</option>);
    }

    if (children.length > 0) {
        return (
            <span>
                {!updateForm ? (
                    <form method="post">
                        <h4>Update a Child</h4>

                        <div className="input-box">
                            <select name="selectedChild" onChange={handleChange} value={childValue}>
                                <option key="0" value="0" data-tag="0">Please select a child...</option>
                                {children}
                            </select>
                        </div>

                        <div className="input-box">
                            {formValue.selectedChild != 0 && message == '' ? <div><button type="submit" onClick={submitUpdate}>Update</button> <button type="submit" onClick={submitRemove}>Remove</button></div> : ''}
                        </div>
                    </form>) : ''}

                <p className="paragraph">
                    {message}
                </p>

                {updateForm ? (
                    <form action="" method="post" onSubmit={handleSubmit(saveUpdate)}>
                        <div className="input-box">
                            <b>Child name:</b>
                            <br />
                            <small><i>This will be the name displayed on the site.</i></small>
                            <br />
                            <input
                                type="text"
                                {...register("name", {
                                    required: "Please enter your child's name.",
                                })}
                                maxLength="50" />

                            {errors.name && <span role="form-error">{errors.name.message}</span>}
                        </div>

                        <div className="input-box">
                            <b>Birthday</b>
                            <br />
                            <select
                                {...register("month")}>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>

                            <select
                                {...register("day")}>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>

                            <select
                                {...register("year")}>
                                {years}
                            </select>
                        </div>

                        <div className="input-box">
                            <b>Good or Bad?</b>
                            <br />
                            <select
                                {...register("status")}>
                                <option value="0">Bad</option>
                                <option value="1">Good</option>
                            </select>
                        </div>

                        <div className="input-box">
                            <b>Reason:</b>
                            <br />
                            <small><i>What is their reason for being good or bad?</i></small>
                            <br />
                            <textarea
                                rows="5"
                                {...register("reason", {
                                    required: "Please enter a reason for why your child is good or bad.",
                                })}>
                            </textarea>

                            {errors.reason && <span role="form-error">{errors.reason.message}</span>}

                            <span className="small-font">
                                <b>To protect your child from inappropriate content, our filter will change any reason to "Easter Bunny is in the process of updating your status!", if detected to contain inappropriate or misleading key phrases.</b>
                            </span>
                        </div>

                        <div className="input-box">
                            <button type="submit">Save</button>
                        </div>
                    </form>
                ) : ''}
            </span>
        )
    } else {
        return (
            <div className="center-content">
                No children to update.
            </div>
        )
    }
}