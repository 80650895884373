import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Axios from "axios";

export default function Login(props) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  /**
   * Handles login submission
  */
  const submitLogin = (d) => {
    Axios.post("/login", {
      loginInfo: d,
    }).then((response) => {
      if (!response.data.auth) {
        props.setLoginStatus(false);
        props.setLogAttempt(false);
      } else {
        // Check if admin
        if (response.data.result[0].admin == 1) {
          props.setAdminStatus(true);
        }
        props.setLoginStatus(true);
        props.setLogAttempt(true);
        props.setUserID(response.data.result[0].id);
        props.setCredits(response.data.result[0].credits);
        props.navigate();
      }
    });
  }

  if (!props.loginStatus) {
    return (
      <div>
        <img src="/images/login_bunny.png" alt="A bunny with an egg." className="side-images" />

        <form method="post" onSubmit={handleSubmit(submitLogin)}>
          <div className="input-box">
            <b>E-mail:</b>
            <br />
            <input {...register("email", { required: "Enter your e-mail." })} type="text" />

            {errors.email && <span role="form-error">{errors.email.message}</span>}
          </div>

          <div className="input-box">
            <b>Password:</b>
            <br />
            <input {...register("password", { required: "Enter your password." })} type="password" />

            {errors.password && <span role="form-error">{errors.password.message}</span>}
          </div>

          <div className="input-box">
            <button type="submit">Login</button>
          </div>
        </form>

        {!props.logAttempt ? <p role="form-error">Invalid username or password.</p> : ''}

        <p>
          <Link to="/forgot/">Forgot your password?</Link>
        </p>
      </div>
    )
  } else {
    return (
      <div>
        <p className="center-content">
          You are already logged in.
        </p>
      </div>
    )
  }
}